import { Class } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { StudentGroupType, YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { estimateYearGroupFromStudents } from '@sparx/mis-sync-import/src/MisSyncImport/components/MISClassesPanel/utils';
import { ConfigurableYearGroupId, WondeClass } from '@sparx/mis-sync-import/src/types';
import {
  getWondeIDFromExternalID,
  isGroupExpired,
  parseYearGroup,
  wondeIDOfGroup,
} from '@sparx/mis-sync-import/src/utils';
import { ImmerReducer } from 'use-immer';

import { SyncConfig } from './types';

type AddClasses = {
  type: 'add_classes';
  /**
   * List of classes to add for import.
   */
  classes: WondeClass[];
};

type RemoveClass = {
  type: 'remove_class';
  /**
   * Resource name of the class that should be removed as part of the import.
   */
  className: string;
};

type RemoveAddedClass = {
  type: 'remove_added_class';
  /**
   * Wonde ID of the class that should be removed from the added classes list.
   */
  classId: string;
};

type RestoreRemovedClass = {
  type: 'restore_removed_class';
  /**
   * Resource name of the existing sparx class that should be removed from the removed classes
   * list.
   */
  className: string;
};

type SetYearGroupId = {
  type: 'set_year_group_id';
  /**
   * The identifier to use when searching for the class. This can be either a wonde ID or a group
   * resource name.
   */
  classIdentifier: string;
  /**
   * Year group ID to set for the specified class. This is the year group ID that will be sent in
   * the sync request.
   */
  yearGroupId: string;
};

export type ConflictResolution = {
  studentWondeID: string;
  classWondeID: string;
};

type ResolveConflicts = {
  type: 'resolve_conflicts';
  conflictResolutions: ConflictResolution[];
};

type UpdateClasses = {
  type: 'update_classes';
  /**
   * List of school groups to use as existing classes. They will be filtered down to exclude tutor
   * groups and expired groups, as well as setting the configured year group id to the existing year
   * group ID on the group.
   */
  classes: Group[];

  /**
   * List of classes from Wonde. These are used to calculate any Sparx Groups that have been removed
   * from Wonde, in which case we'll remove that group from Sparx and try to find a matching Wonde
   * class to add.
   */
  wondeClasses: Class[];

  /**
   * Record of yearGroups in the school. These are used to find a yeargroup for any classes added
   * automatically as a result of an existing group being removed from Wonde and importing an
   * equivalent with the same name.
   */
  yearGroups: Record<string, YearGroup>;
  /**
   * Optional group type to filter student groups.
   */
  groupTypeFilter?: StudentGroupType;
};

type Reset = {
  type: 'reset';
};

type AddClassMatch = {
  type: 'add_class_match';
  wondeID: string;
  sparxClass?: Group;
};

type ResetClassMatches = {
  type: 'reset_class_matches';
};

export type Action =
  | AddClasses
  | RemoveAddedClass
  | RemoveClass
  | RestoreRemovedClass
  | SetYearGroupId
  | ResolveConflicts
  | UpdateClasses
  | Reset
  | AddClassMatch
  | ResetClassMatches;

/**
 * Reset Sparx Staff override class matches back to the default - i.e. existing classes will match to their current
 * Wonde class and classes being added will match to a Sparx class that has been removed from Wonde which has the same
 * name if there is one
 * @param draft
 */
const resetClassMatches = (draft: SyncConfig) => {
  draft.classMatches = {};

  for (const addedClass of draft.classesToAdd) {
    const removedClassMatch = draft.sparxGroupsRemovedFromWonde.find(
      g => g.displayName === addedClass.name,
    );
    if (removedClassMatch) {
      draft.classMatches[addedClass.id] = removedClassMatch;
    }
  }

  const removedFromWonde = new Set<string>();
  for (const classRemoved of draft.sparxGroupsRemovedFromWonde) {
    const wondeID = wondeIDOfGroup(classRemoved);
    if (wondeID) {
      removedFromWonde.add(wondeID);
    }
  }

  for (const existingClass of draft.existingClasses) {
    const wondeID = wondeIDOfGroup(existingClass);
    if (wondeID && !draft.classMatches[wondeID] && !removedFromWonde.has(wondeID)) {
      draft.classMatches[wondeID] = existingClass;
    }
  }
};

export const makeSyncConfigReducer =
  (debug: boolean): ImmerReducer<SyncConfig, Action> =>
  (draft: SyncConfig, action: Action) => {
    const start = performance.now();
    if (debug) {
      console.info('[syncConfig] BEFORE', {
        syncConfig: JSON.parse(JSON.stringify(draft)),
        action,
        start,
      });
    }

    switch (action.type) {
      case 'add_classes':
        draft.classesToAdd.push(...action.classes);

        // Automatically match an added class to an expired class with the same Wonde ID in its external ID, although
        // not if that expired has been matched to a different Wonde class.
        for (const addedClass of action.classes) {
          const matchingExpiredClass = draft.existingExpiredClasses[addedClass.id];
          if (
            matchingExpiredClass &&
            !Object.values(draft.classMatches).find(
              (c: Group) => c.name === matchingExpiredClass.name,
            )
          ) {
            draft.classMatches[addedClass.id] = matchingExpiredClass;
          }
        }

        break;
      case 'remove_added_class': {
        draft.classesToAdd = draft.classesToAdd.filter(c => c.id !== action.classId);

        // Remove any conflict resolutions that reference the class that was removed
        for (const conflictResolution of Object.entries(draft.conflictResolutions)) {
          if (conflictResolution[1] === action.classId) {
            delete draft.conflictResolutions[conflictResolution[0]];
          }
        }

        // Remove any class matches that reference the class that was removed
        delete draft.classMatches[action.classId];

        break;
      }
      case 'remove_class':
        draft.classesToRemove.push(action.className);

        // Remove any conflict resolutions that reference the class that was removed.
        removeExistingClassFromConflictResolutions(draft, action.className);

        break;
      case 'restore_removed_class': {
        draft.classesToRemove = draft.classesToRemove.filter(
          className => className !== action.className,
        );
        break;
      }
      case 'set_year_group_id': {
        const set = setYearGroupId(
          draft.classesToAdd,
          c => c.id === action.classIdentifier,
          action.yearGroupId,
        );
        if (!set) {
          setYearGroupId(
            draft.existingClasses,
            c => c.name === action.classIdentifier,
            action.yearGroupId,
          );
        }
        break;
      }
      case 'resolve_conflicts': {
        for (const resolution of action.conflictResolutions) {
          draft.conflictResolutions[resolution.studentWondeID] = resolution.classWondeID;
        }
        break;
      }
      case 'update_classes': {
        const filteredClasses =
          action.groupTypeFilter !== undefined
            ? action.classes.filter(c => c.type === action.groupTypeFilter)
            : action.classes;

        const existingClasses = filteredClasses.filter(
          c => c.type !== StudentGroupType.TUTORGROUP && !isGroupExpired(c),
        );
        // Cycle through Sparx groups and find any that have been removed from Wonde.
        const sparxGroupsRemovedFromWonde = existingClasses.reduce<Group[]>((acc, group) => {
          if (!action.wondeClasses.find(wc => wc.id === wondeIDOfGroup(group))) {
            acc.push(group);
          }
          return acc;
        }, []);

        draft.classesToRemove = sparxGroupsRemovedFromWonde.map(g => g.name);

        // Find any Wonde classes that share a name with any of the Sparx groups removed from Wonde.
        // If we find any, add them to the list of classes to add.
        const classesToAdd: WondeClass[] = [];
        const automaticallyAddedClasses: string[] = [];
        for (const wondeClass of action.wondeClasses) {
          const removedClassMatch = sparxGroupsRemovedFromWonde.find(
            g => g.displayName === wondeClass.name,
          );
          const matchesExistingClass = existingClasses.find(
            c => wondeClass.id === wondeIDOfGroup(c),
          );
          if (removedClassMatch && !matchesExistingClass) {
            const estimatedYearGroup = estimateYearGroupFromStudents(wondeClass.students);
            classesToAdd.push({
              ...wondeClass,
              estimatedYearGroup,
              configuredYearGroupId:
                Object.values(action.yearGroups).find(
                  yg => parseYearGroup(yg.name) === estimatedYearGroup,
                )?.yearGroupID || '',
            });
            automaticallyAddedClasses.push(wondeClass.id);
          }
        }

        draft.classesToAdd = classesToAdd;
        draft.automaticallyAddedClasses = automaticallyAddedClasses;

        draft.sparxGroupsRemovedFromWonde = sparxGroupsRemovedFromWonde;

        draft.existingClasses = existingClasses.map(c => ({
          ...c,
          estimatedYearGroup: null,
          configuredYearGroupId: c.yearGroupId,
        }));

        draft.existingExpiredClasses = filteredClasses
          .filter(c => c.type !== StudentGroupType.TUTORGROUP && isGroupExpired(c))
          .reduce<Record<string, Group>>((acc: Record<string, Group>, group: Group) => {
            const wondeID = getWondeIDFromExternalID('group', group.externalId);
            if (wondeID) {
              acc[wondeID] = group;
            }
            return acc;
          }, {});

        // Calculate the default Sparx staff mode class matches
        resetClassMatches(draft);

        break;
      }
      case 'reset':
        draft.classesToAdd = [];
        draft.classesToRemove = [];
        break;
      case 'add_class_match':
        if (action.sparxClass) {
          draft.classMatches[action.wondeID] = action.sparxClass;
        } else {
          delete draft.classMatches[action.wondeID];
        }
        break;
      case 'reset_class_matches':
        resetClassMatches(draft);
        break;
    }

    if (debug) {
      const duration = performance.now() - start;
      console.info('[syncConfig] AFTER', {
        syncConfig: JSON.parse(JSON.stringify(draft)),
        action,
        end: duration,
      });
    }
  };

function setYearGroupId<T extends ConfigurableYearGroupId>(
  list: T[],
  predicate: (item: T) => boolean,
  yearGroupId: string,
) {
  const target = list.find(predicate);
  if (target) {
    target.configuredYearGroupId = yearGroupId;
    return true;
  }

  return false;
}

/**
 * Remove any conflict resolutions that reference the class that was removed. To do this, we need to find the class
 * Wonde ID
 * @param draft draft state
 * @param className the student group resource name of the class that was removed
 */
const removeExistingClassFromConflictResolutions = (draft: SyncConfig, className: string) => {
  const existing = draft.existingClasses.find(c => c.name === className);
  if (existing) {
    const wondeID = wondeIDOfGroup(existing);
    for (const conflictResolution of Object.entries(draft.conflictResolutions)) {
      if (conflictResolution[1] === wondeID) {
        delete draft.conflictResolutions[conflictResolution[0]];
      }
    }
  }
};
